<template>
  <header :class="{ minor: minor }">
    <div class="welcome row">
      <div class="core row-between lighter">
        <div class="welcome-l row">
          <div class="lang">
            您好<span class="primary" v-if="JSON.stringify(userInfo) != '{}'">
              {{ userInfo.nickname }} </span
            ><span v-if="!commonData.mall_type"
              >，欢迎来到{{ config.name }}！</span
            >
            <span
              class="cursor ml20"
              @click="toExit"
              v-if="JSON.stringify(userInfo) != '{}'"
              >退出登录</span
            >
          </div>
          <div class="login-status">
            <div class="has" v-if="JSON.stringify(userInfo) == '{}'">
              <router-link to="/" class="lighter mr20">登录</router-link>
              <router-link to="/register" class="lighter">注册</router-link>
            </div>
          </div>
        </div>
        <div class="welcome-r row">
          <router-link to="/helpcenter" class="item row">
            <img class="mr10" src="@/assets/icon/icon-help.png" alt="" />
            <span>帮助中心</span>
          </router-link>
          <router-link to="/center" class="item row">
            <img class="mr10" src="@/assets/icon/icon-user.png" alt="" />
            <span>我的中心</span>
          </router-link>
          <router-link to="/orderlist" class="item row" v-if="JSON.stringify(userInfo) != '{}' && (userInfo.company_id != 61 || (userInfo.company_id == 61 && userInfo.group_id==1))">
            <img class="mr10" src="@/assets/icon/icon-order.png" alt="" />
            <span>我的订单</span>
          </router-link>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="header-main">
      <div class="core">
        <div class="main-info row-between">
          <router-link to="/index" v-if="!commonData.mall_type">
            <img
              :src="minor ? config.help_center_logo : config.shop_login_logo"
              alt=""
              class="logo"
            />
          </router-link>
          <router-link to="/prolist" v-else>
            <img :src="commonData.logo" alt="" class="logo" />
          </router-link>
          <div class="info-content row">
            <slot name="selectBox"></slot>
            <ul class="nav-list white nr row" v-if="minor">
              <li class="nav-item" v-if="userInfo.company_id != 61">
                <router-link to="/index">首页</router-link>
              </li>
              <li class="nav-item" v-else> <!--20240304 isea 湘雅跳转商品列表页，其它不变-->
                <router-link to="/prolist">首页</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/center">账户设置</router-link>
              </li>
              <li class="nav-item" v-if="JSON.stringify(userInfo) != '{}' && userInfo.company_id != 61">
                <router-link to="/evaluatelist">评价</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/message">消息</router-link>
              </li>
            </ul>
            <div class="serach-box">
              <div class="search-t row mb5 bg-white">
                <!-- <img src="@/assets/icon/icon-search.png" alt="" /> -->
                <i class="iconfont lg primary">&#xe618;</i>
                <input
                  type="text"
                  :placeholder="hotList ? hotList[0] : '请输入关键词'"
                  class="flex1"
                  v-model="serachKeyValue"
                />
                <button @click="serachHandle()" type="primary" class="white md">
                  搜索
                </button>
              </div>
              <div class="search-b row xs" v-if="hotList">
                <span
                  v-for="(item, index) in hotList.slice(1, hotList.length)"
                  :key="index"
                >
                  <router-link to="" class="warn">{{ item }}</router-link>
                </span>
              </div>
            </div>
            <router-link to="/cart" class="cart-box row-center" v-if="JSON.stringify(userInfo) != '{}' && (userInfo.company_id != 61 || (userInfo.company_id == 61 && userInfo.group_id==1))">
              <!-- <img src="@/assets/icon/icon-cart.png" alt="" /> -->
              <i class="iconfont lg primary mr10">&#xe61a;</i>
              <span class="sm lighter">购物车</span>
              <em
                class="bg-primary xxs white row-center"
                v-if="commonData.cart_num"
                >{{ commonData.cart_num }}</em
              >
            </router-link>
            <!-- <div class="code column-center xs lighter">
              <img :src="config.app_qrcode" alt="" />
              下载APP
            </div> -->
          </div>
        </div>

        <div class="main-nav row-between">
          <div class="nav-l">
            <slot name="navList">
              <div class="nav-list row">
                <div
                  class="nav-item category row bg-primary white"
                  @mouseenter="
                    (classify = true),
                      current != null ? (classifyDetail = true) : false
                  "
                  @mouseleave="
                    (classify = false),
                      (classifyDetail = false),
                      (current = null)
                  "
                  @click="
                    $router.push({
                      path: '/prolist',
                    })
                  "
                >
                  <img
                    src="@/assets/icon/icon-menu.png"
                    alt=""
                    class="menu mr20"
                  />
                  <span class="md">所有产品</span>
                  <img
                    src="@/assets/icon/icon-arrow.png"
                    alt=""
                    class="arrow"
                  />
                </div>
                <router-link
                  class="nav-item row-center"
                  v-for="(item, index) in config.navigat_list"
                  :key="index"
                  :class="{
                    'active primary': item.link == $route.path,
                  }"
                  :to="item.link"
                  ><span>{{ item.name }}</span></router-link
                >
                <!-- <router-link class="nav-item row-center" :class="{
                  'active primary': $route.meta.tabCurrent == 0,
                }" to="/index"><span>首页</span></router-link>
                <router-link class="nav-item row-center" to="/procurement?type=gov" v-if="commonData.theme_type == 1">
                  <span>政府采购</span></router-link>
                <router-link class="nav-item row-center" to="/procurement?type=company"
                  v-if="commonData.theme_type == 1"><span>企业采购</span></router-link>
                <router-link class="nav-item row-center" to="/procurement?type=scene" v-if="commonData.theme_type == 1">
                  <span>场景采购</span></router-link>
                <router-link class="nav-item row-center" to="/promake" v-if="commonData.theme_type == 1" :class="{
                  'active primary': $route.meta.tabCurrent == 5,
                }"><span>产品定制</span></router-link> -->
                <!--<router-link
                  class="nav-item row-center"
                  :class="{
                    'active primary': $route.meta.tabCurrent == 1,
                  }"
                  to="/proall"
                  ><span>全部产品</span></router-link>-->
              </div>
            </slot>
          </div>
          <div class="nav-r row-center">
            <!-- <img src="@/assets/icon/icon-tel.png" alt="" class="" /> -->
            <i class="iconfont lg primary mr5">&#xe678;</i>
            <span class="nr muted">服务专线：{{ config.purchase_phone }}</span>
          </div>
        </div>
        <div class="core pr">
          <el-collapse-transition>
            <div class="nav-classify" v-show="classify">
              <ul>
                <li
                  class="classify-item row"
                  :class="{ active: current == index }"
                  v-for="(item, index) in categoryList"
                  :key="index"
                  @click="
                    $router.push({
                      path: '/prolist',
                      query: {
                        categoryOne: current + 1,
                        categoryId: item.id,
                      },
                    })
                  "
                  @mouseenter="
                    (classify = true),
                      (classifyDetail = true),
                      (current = index)
                  "
                  @mouseleave="(classify = false), (classifyDetail = true)"
                >
                  <!--<img
                    :src="current == index ? item.hover_image : item.image"
                    alt=""
                  />-->
                  <span>{{ item.name }}</span>
                </li>
              </ul>
            </div>
          </el-collapse-transition>
          <ul
            class="classify-detail"
            v-if="categoryList && classifyDetail && current != null"
            @mouseenter="(classify = true), (classifyDetail = true)"
            @mouseleave="(classify = false), (classifyDetail = false)"
          >
            <li
              class="detail-item row md"
              v-for="(item, index) in categoryList[current].sons"
              :key="index"
            >
              <router-link
                :to="`/prolist?categoryOne=${current + 1}&categoryTwo=${
                  index + 1
                }&categoryId=${item.id}`"
                class="item-title normal"
                target="_blank"
                >{{ item.name }}</router-link
              >
              <ul class="name-list pr row wrap lighter flex1">
                <li
                  class="name-item"
                  v-for="(items, indexs) in item.sons"
                  :key="indexs"
                >
                  <router-link
                    :to="`/prolist?categoryOne=${current + 1}&categoryTwo=${
                      index + 1
                    }&categoryThree=${indexs + 1}&categoryId=${items.id}`"
                    target="_blank"
                    >{{ items.name }}</router-link
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="callback float"
      style="margin-right: 500px"
      @click="onClick"
      @mousedown="down"
      @touchstart="down"
      @mousemove="move"
      @touchmove="move"
      @mouseup="end"
      @touchend="end"
      ref="fu"
    >
      <!-- <p @click="callback">返回</p> -->
      <img
        @click="callback"
        src="https://www.dayuwang168.com/images/menu/my_notice1.png"
        alt
      />
    </div>
  </header>
</template>
<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import { EventBus } from '@/main';
export default {
  data() {
    return {
      isLoading: false,
      flags: false, //控制使用
      position: {
        x: 0,
        y: 0,
      },
      nx: "",
      ny: "",
      dx: "",
      dy: "",
      xPum: "",
      yPum: "",
      classify: false,
      classifyDetail: false,
      current: 0,
      categoryList: [], //头部分类列表
      serachKeyValue: "",
    };
  },
  props: {
    // 头部区分
    minor: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    current(val) {},
    userInfo: {
      handler(val) {
        this.$api.configApi().then((res) => {
          this.SET_CONFILG(res.data);
        });
        this.getCommonData();
      },
      deep: true,
    }
    // $route(val) {
    //   this.serachKeyValue = "";
    // },
  },
  created() {
    this.getCommonData();
    this.getCategory();
    this.getSearchList();
    EventBus.$on('global-event-categoryList', (data) => {
       this.categoryList = data;
    });
  },
  mounted() {
    let { head, keyword } = this.$route.query;
    if (head && head == "search") {
      this.serachKeyValue = keyword;
    }
    
    /**
     * 监听悬浮拖拽区域
     */
    this.$nextTick(() => {
      // 获取DOM元素
      let dragArea = this.$refs.dragArea;
      console.log("dragArea",dragArea);
      // 缓存 clientX clientY 的对象: 用于判断是点击事件还是移动事件
      let clientOffset = {};
      // 绑定鼠标按下事件
      dragArea.addEventListener(
        "mousedown",
        (event) => {
          let offsetX = dragArea.getBoundingClientRect().left; // 获取当前的x轴距离
          let offsetY = dragArea.getBoundingClientRect().top; // 获取当前的y轴距离
          let innerX = event.clientX - offsetX; // 获取鼠标在方块内的x轴距
          let innerY = event.clientY - offsetY; // 获取鼠标在方块内的y轴距
          console.log(offsetX, offsetY, innerX, innerY);
          // 缓存 clientX clientY
          clientOffset.clientX = event.clientX;
          clientOffset.clientY = event.clientY;
          // 鼠标移动的时候不停的修改div的left和top值
          document.onmousemove = function (event) {
            dragArea.style.left = event.clientX - innerX + "px";
            dragArea.style.top = event.clientY - innerY + "px";
            // dragArea 距离顶部的距离
            let dragAreaTop =
              window.innerHeight - dragArea.getBoundingClientRect().height;
            // dragArea 距离左部的距离
            let dragAreaLeft =
              window.innerWidth - dragArea.getBoundingClientRect().width;
            // 边界判断处理
            // 1、设置左右不能动
            // dragArea.style.left = dragAreaLeft + "px";

            // 1.设置左侧边界
            if (dragArea.getBoundingClientRect().left <= 0) {
              dragArea.style.left = "0px";
            }
            // 2.设置右侧边界
            if (dragArea.getBoundingClientRect().left >= dragAreaLeft) {
              dragArea.style.left = dragAreaLeft + "px";
            }
            // 3、设置顶部边界
            if (dragArea.getBoundingClientRect().top <= 0) {
              dragArea.style.top = "0px";
            }
            // 4、设置底部边界
            if (dragArea.getBoundingClientRect().top >= dragAreaTop) {
              dragArea.style.top = dragAreaTop + "px";
            }
          };
          // 鼠标抬起时，清除绑定在文档上的mousemove和mouseup事件；否则鼠标抬起后还可以继续拖拽方块
          document.onmouseup = function () {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        },
        false
      );
      // 绑定鼠标松开事件
      dragArea.addEventListener("mouseup", (event) => {
        let clientX = event.clientX;
        let clientY = event.clientY;
        if (
          clientX === clientOffset.clientX &&
          clientY === clientOffset.clientY
        ) {
          console.log("click 事件");
        } else {
          console.log("drag 事件");
        }
      });
    });
  },
  methods: {
    update(msg){
        console.log(msg);
    },
    callback() {
      this.$router.go(-1);
    },
    onRefresh() {
      // window.location.reload();
      setTimeout((res) => {
        console.log(res);
        this.isLoading = false;
      }, 1000);
    },
    down() {
      this.flags = true;
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.position.x = touch.clientX;
      this.position.y = touch.clientY;
      this.dx = this.$refs.fu.offsetLeft;
      this.dy = this.$refs.fu.offsetTop;
    },
    move() {
      if (this.flags) {
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.nx = touch.clientX - this.position.x;
        this.ny = touch.clientY - this.position.y;
        this.xPum = this.dx + this.nx;
        this.yPum = this.dy + this.ny;
        let width = window.innerWidth - this.$refs.fu.offsetWidth; //屏幕宽度减去自身控件宽度
        let height = window.innerHeight - this.$refs.fu.offsetHeight; //屏幕高度减去自身控件高度
        this.xPum < 0 && (this.xPum = 0);
        this.yPum < 0 && (this.yPum = 0);
        this.xPum > width && (this.xPum = width);
        this.yPum > height && (this.yPum = height);
        // if (this.xPum >= 0 && this.yPum >= 0 && this.xPum<= width &&this.yPum<= height) {
        this.$refs.fu.style.left = this.xPum + "px";
        this.$refs.fu.style.top = this.yPum + "px";
        // }
        //阻止页面的滑动默认事件
        document.addEventListener(
          "touchmove",
          function () {
            event.preventDefault();
          },
          false
        );
      }
    },
    //鼠标释放时候的函数
    end() {
      this.flags = false;
    },
    onClick() {
      window.location.href =
        "https://work.weixin.qq.com/kfid/kfcbb9fff861d74a981";
    },

    ...mapMutations(["SET_CONFILG"]),
    ...mapMutations("user", ["SET_COMMON_DATA", "SET_HOT_LIST"]),
    /**
     * @description 获取大雨服务
     * @returns void
     */
    async getCommonData() {
      let commonData = {};
      var classNames = "default";
      await this.$api.commonDataApi().then((res) => {
        commonData = res.data;
        if (commonData.mall_type == 0) {
          document.getElementsByTagName("body")[0].className = "default";
        } else {
          console.log(commonData.mall_type);
          switch (commonData.mall_type) {
            case 1:
              classNames = "two";
            case 2:
              classNames = "three";
              break;
            case 3:
              classNames = "four";
              break;
            case 4:
              classNames = "five";
              break;
            case 5:
              classNames = "six";
              break;
            case 6:
              classNames = "seven";
              break;
            case 7:
              classNames = "eight";
              break;
            case 8:
              classNames = "nine";
              break;

            default:
              break;
          }
          console.log(classNames);
          document.getElementsByTagName("body")[0].className = classNames;
          // commonData.mall_type == 1
          //   ? "three" : "two";owen 2023 0905
        }
        this.SET_COMMON_DATA(JSON.stringify(commonData));
        // document.getElementsByTagName("body")[0].className = "three";
      });
    },
    /**
     * @description 获取大雨分类
     * @returns void
     */
    getCategory() {
      this.$api.getCategoryApi().then((res) => {
        this.categoryList = res.data;
      });
    },

    makeGoodsList() {},
    serachHandle() {
      let key = this.serachKeyValue;
      if (key == "") {
        this.$message({ message: "请输入商品关键字", type: "warning" });
      }
      this.$router.push({
        path: "/search",
        query: { keyword: key, head: "search" },
      });
    },
    /**
     * @description 前端个人中心订单
     * @returns void
     */
    toCenter() {
      this.$router.push({ path: "/order", query: { or: true } });
    },
    /**
     * @description 获取首页热门关键词
     * @return void
     */
    getSearchList() {
      this.$api.getSearchListApi().then((res) => {
        let { hot_lists } = res.data;
        this.SET_HOT_LIST(hot_lists);
      });
    },
    /**
     * @description 退出登录
     * @return void
     */
    toExit() {
     
      this.$confirm("请确认是否退出当前账号", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        this.$store.dispatch("user/EXIT_LOGIN");
        this.$message({
          type: "success",
          message: "退出成功",
        });
        
      });
   
    },
  },

  computed: {
    ...mapState({
      config: (state) => state.config,
      commonData: (state) => state.user.commonData,
      hotList: (state) => state.user.hotList
    }),
    ...mapGetters(["userInfo"]),
  },
};
</script>
<style lang="scss" scoped>
.callback p {
  font-size: 16px;
  color: #fff;
  background: rgba(56, 57, 58, 0.5);
  border-radius: 50%;
  text-align: center;
  width: 50px;
  left: 90%;
  height: 50px;
  line-height: 50px;
  font-family: PingFang SC;
  font-weight: 600;
  box-shadow: 0 0 10px #fff;
}
.callback img {
  display: block;
  width: 50px;
  height: 50px;
  box-shadow: 0 0 10px rgb(133, 129, 129);
  border-radius: 50%;
  background: #fff;
}
.callback {
  position: fixed;
  top: 40px;
  left: 20px;
  z-index: 99999;
}
.float {
  position: fixed;
  right: 200px;

  top: 60%;
  touch-action: none;
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius: 24px;
  line-height: 48px;
  color: white;
}

.drag-area1 {
  position: fixed;
  right: 20px;
  top: 20%;
  z-index: 99999;
  padding: 13px;
  width: fit-content;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: 0px 2px 15px 0px rgba(9, 41, 77, 0.15);
  cursor: move;
  user-select: none;
  text-align: center;
}

.drag-area2 {
  position: fixed;
  right: 20px;
  top: 30%;
  z-index: 99999;
  padding: 13px;
  width: fit-content;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: 0px 2px 15px 0px rgba(9, 41, 77, 0.15);
  cursor: move;
  user-select: none;
  text-align: center;
}

.drag-area3 {
  position: fixed;
  right: 20px;
  top: 40%;
  z-index: 99999;
  padding: 13px;
  width: fit-content;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: 0px 2px 15px 0px rgba(9, 41, 77, 0.15);
  cursor: move;
  user-select: none;
  text-align: center;
}

header {
  position: relative;

  &.minor {
    .code,
    .search-b,
    .main-nav {
      display: none;
    }

    .cart-box {
      .bg-primary {
        background-color: #c81624;
      }

      margin-right: 0 !important;
    }

    .search-t {
      width: 305px !important;
      border: none !important;

      button {
        background-color: #f9f9f9;
        color: #999999;
      }
    }

    .header-main {
      background-color: $-color-primary;

      .logo {
        height: 84px !important;
      }
    }
  }

  &::after {
    content: "";
    height: 2px;
    bottom: 0;
    left: 0;
    width: 100%;
    position: absolute;
    background-color: $-color-primary;
  }

  // 欢迎
  .welcome {
    background-color: #f5f5f5;
    height: 42px;

    .welcome-l {
      .lang {
        margin-right: 45px;
      }
    }

    .welcome-r {
      .item {
        margin-left: 32px;

        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  // 主要部分
  .header-main {
    background-color: #fff;

    .main-info {
      padding: 20px 0;

      .logo {
        width: auto;
        height: 95px;
      }

      .nav-list {
        height: 38px;
        margin-right: 30px;

        .nav-item {
          a {
            padding: 0 15px;
            display: block;

            &:hover {
              color: white !important;
            }
          }
        }
      }

      .info-content {
        padding-top: 18px;
        align-items: flex-start;

        .serach-box {
          margin-right: 13px;

          .search-t {
            border-width: 2px;
            border-color: $-color-primary;
            border-style: solid;
            height: 38px;
            width: 470px;

            .iconfont {
              margin: 0 13px;
            }

            button {
              height: 38px;
              width: 90px;
              position: relative;
              right: -1px;
            }
          }

          .search-b {
            height: 20px;

            span {
              margin-right: 15px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        .cart-box {
          width: 121px;
          height: 38px;
          border: 1px solid #c0c0c0;
          background: #f9f9f9;
          position: relative;

          // margin-right: 35px;
          em {
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            right: -4px;
            top: -4px;
          }
        }

        .code {
          width: 73px;
          height: 89px;
          border: 1px solid #eaeaea;

          img {
            width: 63px;
            height: 63px;
          }
        }
      }
    }

    // 导航
    .main-nav {
      .nav-l {
        cursor: pointer;

        .nav-list {
          height: 47px;

          /deep/ .nav-item {
            height: 100%;
            padding: 0 30px;

            &.active {
              span {
                width: 100%;
                height: 100%;
                position: relative;
                display: block;
                line-height: 47px;

                &::before {
                  position: absolute;
                  content: "";
                  bottom: 0;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 0px;
                  height: 0px;
                  border-width: 6px;
                  border-style: solid;
                  border-color: transparent transparent $-color-primary
                    transparent;
                }
              }
            }

            &.category {
              width: 221px;
              padding: 0 0 0 30px;

              .menu {
                width: 14px;
                height: 14px;
              }

              .arrow {
                width: 8px;
                height: 5px;
                margin-left: 42px;
              }
            }
          }
        }
      }

      .nav-r {
        img {
          width: 14px;
          height: 14px;
        }
      }
    }

    // 导航分类
    .nav-classify {
      cursor: pointer;
      position: absolute;
      left: 0;
      width: 221px;
      height: 600px;
      background-color: rgb(67, 170, 233);
      opacity: 0.8;
      //background-color: rgba(0, 0, 0, 0.5);
      z-index: 99;

      ul {
        height: 600px;
      }

      .classify-item {
        height: 70px;
        padding-left: 35px;
        color: $-color-white;

        // &.active:hover{
        //   color: $-color-primary;
        //   background-color: $-color-white;
        // }
        &.active,
        &:hover {
          color: $-color-primary;
          background-color: $-color-white;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 16px;
        }
      }
    }
    //三级页面大标签
    .classify-detail {
      position: absolute;
      left: 221px;
      right: 0;
      height: 600px;
      z-index: 99;
      background-color: $-color-white;
      padding: 5px 40px;
      overflow-y: scroll;
      &:hover {
        background-color: white;
        // opacity: 0.8;
      }

      /* 设置滚动条的样式 */
      &::-webkit-scrollbar {
        width: 10px;
      }

      /* 滚动槽 */
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
        border-radius: 10px;
      }

      /* 滚动条滑块 */
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
      }

      &::-webkit-scrollbar-thumb:window-inactive {
        background: $-color-muted;
      }

      .detail-item {
        line-height: 1.7;
        align-items: flex-start;
        border-bottom: $-dashed-border;

        .item-title {
          //width: 81px;
          padding: 15px 15px 15px 0;
        }

        .name-list {
          padding: 15px 0;
          padding-left: 48px;

          &::after {
            content: "";
            position: absolute;
            width: 8px;
            height: 14px;
            top: 24px;
            left: 0;
            background-size: cover;
            background-image: url(~@/assets/icon/icon-arrow1.png);
          }

          .name-item {
            margin-right: 30px;
          }
        }
      }
    }
  }
}
</style>
